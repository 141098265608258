.sidebarKmsMainChild {
    position: relative;
    max-width: 60px;
}
.sidebarKmsMore {
    font-size: 1em;
    padding: 0 3px;
}
.sidebarKmsNotLast {
    font-size: 0.8em;
}
.sidebarKmsLast {
    font-size: 0.8em;
    display: inline-block;
    position: relative;;
}

.sidebarKmsKms {
    border-radius: 100px;
    color: white;
    font-size: 0.85em;
    height: 13px;
    line-height: initial;
    margin: 3px;
    padding: 0 4px;
    position: relative;
    width: auto;
}
.sidebarKmsIndex{
    background: #3498db none repeat scroll 0 0;
    border-radius:50%; color: white;
    font-size: 0.85em; height: auto; line-height: initial; margin: 0;padding: 0 4px; position: relative;
}
.sidebarKmsNotLastIcon{
    height: auto; line-height: initial; margin: 0; position: relative; width: auto;
}
.sidebarKmsFlag {
    border-radius: 50%;
    font-size: 0.85em;
    height: auto;
    line-height: initial;
    margin: 0;
    position: relative;
}

.truck-info, .trailer-info {
    display: flex;
    justify-content: space-between;
}