.plan-checkup-btn .fa {
    font-size: 1.3em;
}

.badge-checkup-reminders {
    font-size: 0.85em;
    line-height: initial;
    color: white;
    background: #03b912;
    width: auto;
    height: auto;
    margin: 0;
    border-radius: 100px;
    position: absolute;
    top: -4px;
    right: -3px;
    padding: 0px 4px;
}

.checkupToBottom{
    top: 9px !important;
    font-size: 0.75em;
}

.technical-checkup-list-item {
    font-size: 1.1em;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    box-shadow: 0px 1px 0px 0px rgba(50, 50, 50, 0.3);
    position: relative;
}

.technical-checkup-details {
    font-size: 0.9em;
    margin-top: 5px;
}

.technical-checkup-status-NEW {
    background-color: #0480be;
    color: white;
}

.technical-checkup-status-NEW .technical-checkup-details {
    color: rgba(255, 255, 255, 0.70);
}

.technical-checkup-status-RESOLVED {
    background-color: #d2e7dc;
    color: #222;
}

.technical-checkup-status-RESOLVED .technical-checkup-details {
    color: rgba(32, 32, 32, 0.70);
}


.technical-checkup-status-DISABLED {
    background-color: #fefeff;
    color: #484848;
    border: solid rgba(50, 50, 50, 0.2) 1px ;
}

.technical-checkup-status-DISABLED .technical-checkup-details {
    color: rgba(65, 65, 65, 0.7);
}

.technical-checkup-details > div{
    display: inline-block;
    margin-right: 5px;
}

#technical-checkup-container {
    max-height: 100%;
    overflow-y: auto;
}

.technical-checkup-block {
    width: 49%;
}

.technical-checkup-plan-visit-btn {
    position: absolute;
    top: 0px;
    right: 0px;
}



.technical-checkup-list, .technical-checkup-plan-list, .technical-checkup-postpone-list {
    list-style: none;
    padding: 0px;
}

.technical-checkup-list-item {
    font-size: 1.1em;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    box-shadow: 0px 1px 0px 0px rgba(50, 50, 50, 0.3);
    position: relative;
}


.technical-checkup-details {
    font-size: 0.9em;
    margin-top: 5px;
}

.technical-checkup-details > div{
    display: inline-block;
    margin-right: 5px;
}

.technical-checkup-postpone-list-item > div {
    display: inline-block;
    margin-right: 5px;
}

.technical-checkup-details .remaining-days-negative {
    color: red;
    font-weight: bold;
}


#technical-checkup-container {
    max-height: 100%;
    overflow-y: auto;
}

.technical-checkup-block {
    width: 49%;
    display: inline-block;
    vertical-align:top;
}

.technical-checkup-plan-block {
    width: 49%;
    display: inline-block;
    vertical-align:top;
}





.technical-checkup-plan-list-item {
    border-radius: 5px;
    box-shadow: 0px 1px 0px 0px rgba(50, 50, 50, 0.3);
    /*background-color: rgb(234, 234, 234);*/
    /*color: #484848;*/
    border: solid rgba(50, 50, 50, 0.2) 1px ;
    padding: 8px;
    background-color: #efefef;
    color: #484848;
    margin-bottom: 5px;
    position: relative;
}

.plan-items-list {
    list-style: none;
}

.plan-items-list .plan-item, .technical-checkup-list-item, .technical-checkup-postpone-list-item {
    border-radius: 5px;
    box-shadow: 0px 1px 0px 0px rgba(50, 50, 50, 0.3);
    margin-top: 5px;
    border: solid rgba(50, 50, 50, 0.2) 1px;
    background-color: #e8f3ea;
    padding: 2px;
    position: relative;
}

.technical-checkup-postpone-list-item {
    padding: 5px;
}

.technical-checkup-buttons {
    position: absolute;
    display: inline-block;
    top: 0px;
    right: 0px;
    font-size: 1.4em;
}

.technical-checkup-plan-disable {
    position: absolute;
    display: inline-block;
    top: 0px;
    right: 0px;
    font-size: 1.4em;
}

.technical-checkup-plan-detail {
    font-weight: 500;
    margin-top: 5px;
}

.checkup-reminders-list {
    list-style: none;
    padding: 0px;
}

.checkup-reminder-list-item {
    position: relative;
    background-color: beige;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 1px 0px 0px rgba(50, 50, 50, 0.3);
    border: solid rgba(50, 50, 50, 0.2) 1px;
    margin-top: 5px;
}

.reminder-control-buttons {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;
}

.reminder-detail {
    display:inline-block;
    margin-right: 5px;
}

#technicalPlanVisitTime input {width:246px;}

#technicalPlanService input{width: 188px;}

.technicalCheckupStatus-DEFAULT .technicalCheckupCommand,
.technicalCheckupStatus-DEFAULT .technicalCheckupCommand:hover {
    color: black;
}

.technicalCheckupStatus-WARNING .technicalCheckupCommand {
    display: inline-block;
    color: #e05a00 !important;
    animation-timing-function: linear;
}

.technicalCheckupStatus-DANGER .technicalCheckupContainer {
    border-radius: 10px;
}

.technicalCheckupStatus-DANGER .technicalCheckupCommand {
    display: inline-block;
    color: #da0000 !important;
    animation: colorPulseRed 0.5s, shake 0.3s alternate;
    animation-iteration-count: 15;
    animation-timing-function: linear;
}

.technicalCheckupStatus-FATAL .technicalCheckupContainer {
    animation: backgroundColorPulseRed 0.5s alternate;
    animation-iteration-count: 15;
    background-color: #ca1600;
    border-radius: 10px;
    animation-timing-function: linear;
}

.technicalCheckupStatus-FATAL .technicalCheckupCommand {
    color: black;
    display: inline-block;
    animation: shake 0.3s alternate;
    animation-iteration-count: 15;
    animation-timing-function: linear;
}

.not_active {
    display: none;
}

.bounce {
    animation: bounce 1s infinite;
    animation-iteration-count: 15;
}

.badge-not-read {
    font-size: 0.85em;
    line-height: initial;
    color: white;
    background: #03b912;
    width: auto;
    height: auto;
    margin: 0;
    border-radius: 100px;
    position: absolute;
    top: -1px;
    right: -3px;
    padding: 0px 4px;
}

.badge-not-read-events {
    font-size: 0.85em;
    line-height: initial;
    color: white;
    background: #3498db;
    width: auto;
    height: auto;
    margin: 0;
    border-radius: 100px;
    position: absolute;
    top: -1px;
    right: -3px;
    padding: 0px 4px;
}

.badge-not-received {
    font-size: 0.85em;
    line-height: initial;
    color: white;
    background: red;
    width: auto;
    height: auto;
    margin: 0;
    border-radius: 100px;
    position: absolute;
    bottom: -1px;
    right: -3px;
    padding: 0px 4px;
}

.badge-not-delivered {
    font-size: 0.85em;
    line-height: initial;
    color: white;
    background: #ffa726;
    width: auto;
    height: auto;
    margin: 0;
    border-radius: 100px;
    position: absolute;
    bottom: -1px;
    left: -3px;
    padding: 0px 4px;
}